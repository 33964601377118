var t = Object.defineProperty;
var r = (a, e, o) => e in a ? t(a, e, { enumerable: !0, configurable: !0, writable: !0, value: o }) : a[e] = o;
var d = (a, e, o) => (r(a, typeof e != "symbol" ? e + "" : e, o), o);
import { JwtToken as l } from "./JwtToken.js";
class n extends l {
  constructor(o) {
    super(o);
    d(this, "payload");
    this.payload = this.decodePayload();
  }
  decodePayload() {
    return super.decodePayload();
  }
  getEmail() {
    var o;
    return (o = this.payload) == null ? void 0 : o.email;
  }
}
export {
  n as GoogleToken
};
