import {
  useRef, useEffect, useState, PropsWithChildren,
} from 'react';
import { createPortal } from 'react-dom';
import { PORTAL_ID } from '../../constants';

type Props = {
  targetId?: string
}

export default function ClientOnlyPortal({
  children,
  targetId = PORTAL_ID,
}: PropsWithChildren<Props>) {
  const ref = useRef<HTMLElement | null>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.getElementById(targetId);
    if (ref.current) {
      setMounted(true);
    } else {
      console.error("Couldn't find element with ID: ", targetId);
    }
  }, [targetId]);

  return mounted ? createPortal(children, ref.current) : null;
}
