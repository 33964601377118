import { css, keyframes } from '@emotion/react';

const animFadeInFromBottom = keyframes`
    0% {
        opacity: 0.3;
        margin-top: 10px;
    }
    100% {
        opacity: 1;
        margin-top: 0px;
    }
`;

export const styFadeInFromBottom = css({
  animation: `${animFadeInFromBottom} ease 0.4s`,
});

export const animShimmer = keyframes`
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
`;
