import classNames from 'classnames';
import { PORTAL_ID } from '../../constants';
import ClientOnlyPortal from '../ClientOnlyPortal';

function Loading({ loading }: {loading: boolean}) {
  if (!loading) return null;
  return (
    <ClientOnlyPortal targetId={PORTAL_ID}>
      <div
        className={classNames('loader-wrapper', {
          'is-active': loading,
        })}
      >
        <div className="loader-box">
          <div className="loader is-loading" />
        </div>
      </div>
    </ClientOnlyPortal>
  );
}

export default Loading;
