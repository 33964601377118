import { css } from '@emotion/react';

export const styTextType = {
  link: css({
    color: 'var(--indigo-400)',
    cursor: 'pointer',

    '&:hover': {
      color: 'var(--indigo-300)',
    },
  }),
  regular: css({
    color: 'var(--neutral-700)',
  }),
  main: css({
    color: 'var(--neutral-900)',
  }),
  disabled: css({
    color: 'var(--neutral-500)',
  }),
  error: css({
    color: 'var(--error-400)',
  }),
  mute: css({
    color: 'var(--neutral-600)',
  }),
  white: css({
    color: 'white',
  }),
  'white-secondary': css({
    color: 'var(--text-white-transparent)',
    opacity: 0.8,
  }),
};

export const styTextSize = {
  text: {
    xs2: css({
      fontSize: '12px',
      lineHeight: '18px',
    }),
    xs: css({
      fontSize: '13px',
      lineHeight: '20px',
    }),
    sm: css({
      fontSize: '14px',
      lineHeight: '20px',
    }),
    md: css({
      fontSize: '16px',
      lineHeight: '24px',
    }),
    lg: css({
      fontSize: '18px',
      lineHeight: '28px',
      letterSpacing: '-0.01em',
    }),
    xl: css({
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.01em',
    }),
    xl2: css({
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '-0.01em',
    }),
  },
  display: {
    sm: css({
      fontSize: '28px',
      lineHeight: '42px',
      letterSpacing: '-0.01em',
    }),
    md: css({
      fontSize: '30px',
      lineHeight: '42px',
    }),
    lg: css({
      fontSize: '32px',
      lineHeight: '44px',
    }),
  },
};

export const styTextWeight = {
  regular: css({
    fontWeight: '400',
  }),
  medium: css({
    fontWeight: '500',
  }),
  semibold: css({
    fontWeight: '600',
  }),
  bold: css({
    fontWeight: '700',
  }),
};

export const styTextEllipsis = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});
