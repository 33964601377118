import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { GoogleToken, JwtToken } from '@typedream/data';
import GoogleSignInScript from '@/head/GoogleSignInScript';
import useWindowSize from '@/utils/useWindowSize';

import { GOOGLE_CLIENT_ID } from '@/constants';

function GoogleSignInButton({ setoauthJWT, login }: {
  setoauthJWT: Dispatch<SetStateAction<JwtToken>>,
  login: boolean,
}) {
  const size = useWindowSize();
  const [showButton, setshowButton] = useState(true);

  function renderButton() {
    if (window.google) {
      window.google?.accounts?.id?.prompt(); // also display the One Tap dialog
      window.google?.accounts?.id?.renderButton(
        document.getElementById('buttonDiv'),
        {
          size: 'large',
          width: 400,
          logo_alignment: 'center',
        },
      );
    }
  }

  useEffect(() => {
    renderButton();
  }, [size?.width]);

  useEffect(() => {
    setshowButton(true);
  }, [login]);

  function handleCredentialResponse(response) {
    const credJWT = new GoogleToken(response.credential);
    setoauthJWT(credJWT);

    // Don't use js removeChild so we can re-show the button
    // if `login` parameter changes
    setshowButton(false);
  }

  function loadGoogleSignIn() {
    if (window.google) {
      window.google?.accounts?.id?.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
        auto_select: false,
        prompt_parent_id: 'oneTap',
      });
    }
  }

  useEffect(() => {
    if (window) {
      const googleScript = document.getElementById('google-sign-in-script');
      if (window?.google) {
        loadGoogleSignIn();
        renderButton();
      }
      googleScript?.addEventListener('load', () => {
        loadGoogleSignIn();
        renderButton();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GoogleSignInScript />
      {showButton && <div id="buttonDiv" />}
    </>
  );
}

export default GoogleSignInButton;
