import { css, keyframes } from '@emotion/react';
import { SHADOW_XS } from '../../Tokens/BoxShadow';
import { ButtonType } from './type';

const animationSpin = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;

export const styButton = css({
  alignItems: 'center',
  border: 'none',
  boxShadow: SHADOW_XS,
  display: 'flex',
  fontWeight: '500',
  justifyContent: 'center',
  gap: '8px',
  cursor: 'pointer',

  '&:disabled,&[disabled]': {
    background: 'var(--neutral-200)',
    color: 'var(--neutral-500)',
  },
  '& > .styButtonLoading': {
    animation: `${animationSpin} 1s linear infinite`,
  },
});

export const styButtonVariant = {
  destructive: css({
    backgroundColor: 'var(--error-400)',
    borderColor: 'var(--error-400)',
    color: 'var(--neutral-25)',
  }),
  primary: css({
    backgroundColor: 'var(--primary-400)',
    borderColor: 'var(--primary-400)',
    color: 'var(--neutral-25)',
  }),
  tertiary: css({
    backgroundColor: 'var(--neutral-25)',
    border: '1px solid var(--neutral-300)',
    color: 'var(--neutral-700)',
  }),
  black: css({
    backgroundColor: 'var(--neutral-900)',
    borderColor: 'var(--neutral-900)',
    color: 'var(--neutral-25)',
  }),
  orange: css({
    backgroundColor: 'var(--orange-400)',
    borderColor: 'var(--orange-400)',
    color: 'var(--neutral-25)',
  }),
};

export const styButtonGhost = {
  destructive: css({
    backgroundColor: 'var(--neutral-25)',
    border: '1px solid var(--error-400)',
    color: 'var(--error-400)',
  }),
  primary: css({
    backgroundColor: 'var(--neutral-25)',
    border: '1px solid var(--primary-400)',
    color: 'var(--primary-400)',
  }),
};
export const styButtonWidth = {
  full: css({
    width: '100%',
  }),
  fitContent: css({
    width: 'fit-content',
  }),
};

export const styButtonSize = {
  lg: css({
    fontSize: '20px',
    lineHeight: '28px',
    padding: '10px 18px',
    '& > .styButtonLoading': {
      width: '20px',
    },
  }),
  md: css({
    fontSize: '16px',
    lineHeight: '24px',
    padding: '10px 18px;',
    '& > .styButtonLoading': {
      width: '16px',
    },
  }),
  sm: css({
    fontSize: '14px',
    lineHeight: '20px',
    padding: '10px 16px',
    '& > .styButtonLoading': {
      width: '14px',
    },
  }),
  xs: css({
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 16px',
    '& > .styButtonLoading': {
      width: '14px',
    },
  }),
};

export const styButtonRadius = {
  md: css({
    borderRadius: '15px',
  }),
  sm: css({
    borderRadius: '8px',
  }),
};

export const styButtonBlock = css({
  width: '100%',
});

export const styButtonHoverEffect = {
  destructive: css({
    backgroundColor: 'var(--error-500)',
    borderColor: 'var(--error-500)',
  }),
  primary: css({
    backgroundColor: 'var(--primary-500)',
    borderColor: 'var(--primary-500)',
  }),
  tertiary: css({
    backgroundColor: 'var(--neutral-25)',
    border: '1px solid var(--neutral-300)',
  }),
  'destructive-ghost': css({
    color: 'var(--error-500)',
    border: '1px solid var(--error-500)',
  }),
  'primary-ghost': css({
    color: 'var(--primary-500)',
    border: '1px solid var(--primary-500)',
  }),
  orange: css({
    backgroundColor: 'var(--orange-500)',
    borderColor: 'var(--orange-500)',
  }),
};

export const styButtonActiveEffect = {
  destructive: css({
    backgroundColor: 'var(--error-700)',
    borderColor: 'var(--error-700)',
  }),
  primary: css({
    backgroundColor: 'var(--primary-700)',
    borderColor: 'var(--primary-700)',
  }),
  tertiary: css({
    backgroundColor: 'var(--neutral-100)',
    border: '1px solid var(--neutral-300)',
    color: 'var(--neutral-900)',
  }),
  'destructive-ghost': css({
    color: 'var(--error-700)',
    border: '1px solid var(--error-700)',
    backgroundColor: 'var(--neutral-100)',
  }),
  'primary-ghost': css({
    color: 'var(--primary-700)',
    border: '1px solid var(--primary-700)',
    backgroundColor: 'var(--neutral-100)',
  }),
  orange: css({
    backgroundColor: 'var(--orange-700)',
    borderColor: 'var(--orange-700)',
  }),
};

export const styButtonEffect = ({ type, ghost }:{type:ButtonType, ghost:boolean }) => css({
  '&:hover': styButtonHoverEffect?.[`${type}${ghost ? '-ghost' : ''}`],
  '&:active': styButtonActiveEffect?.[`${type}${ghost ? '-ghost' : ''}`],
});
