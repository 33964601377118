import { useMemo } from 'react';
import type { TypographyPropsType } from './type';
import { FONT_SIZE_INDEX, FONT_TYPE_INDEX, FONT_WEIGHT_INDEX } from './constants';
import {
  styTextEllipsis, styTextSize, styTextType, styTextWeight,
} from './style';

function Typography({
  body = 'text-sm-medium',
  children,
  margin = '',
  type = 'regular',
  customStyle = [],
  ellipsis,
  ...props
}: TypographyPropsType) {
  const typographyStyles = useMemo(() => {
    if (body.length === 2) {
      return [...body.split('-'), 'regular'];
    }
    return body.split('-');
  }, [body]);

  const cssStyle = [
    styTextSize?.[typographyStyles?.[FONT_TYPE_INDEX]]?.[typographyStyles?.[FONT_SIZE_INDEX]],
    styTextWeight?.[typographyStyles?.[FONT_WEIGHT_INDEX]],
    styTextType?.[type],
    ellipsis && styTextEllipsis,
    ...customStyle,
  ];

  const dynamicStyle = {
    margin: margin || '',
  };

  return (
    <div
      css={cssStyle}
      style={dynamicStyle}
      {...props}
    >
      {children}
    </div>
  );
}

export default Typography;
