const SHADOW_XS = '0px 1px 2px rgba(16, 24, 40, 0.05)';
const SHADOW_SM = '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)';
const SHADOW_MD = '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)';
const SHADOW_LG = '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)';
const SHADOW_XL = '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)';

export {
  SHADOW_XS,
  SHADOW_SM,
  SHADOW_MD,
  SHADOW_LG,
  SHADOW_XL,
};
