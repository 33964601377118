import { USER_STORAGE_KEY } from '../constants';
import { IUser } from '../types';

export default function getUserID(): string {
  const userStr = localStorage?.getItem(USER_STORAGE_KEY);
  if (userStr) {
    const user: IUser = JSON.parse(userStr);
    return user.id;
  }
  return null;
}
