import { useState } from 'react';
import classNames from 'classnames';

import Button from '@/sharedComponents/v1/Components/Button';
import { styFadeInFromBottom } from '@/styles/AnimationStyle';
import styles from '@/styles/Onboarding.module.css';
import { Typography } from '@/sharedComponents/v1/Components/Typography';

import type { FormEvent } from 'react';
import { shuffleContents } from '@/utils/shuffleArray';
import formStyle from '../FormStyle.module.css';
import { DROPDOWN_CONTENT, OPTION_OTHER } from './constant';

import type { SignupFormPropsType } from './type';
import { styFormInputContainer } from './style';

function SignupForm({
  email,
  emailDisabled = false,
  errEmail,
  errSource,
  inputSource,
  inputSourceSpecific,
  loading,
  onSubmit,
  setEmail,
  setInputSource,
  setInputSourceSpecific,
  setshowSourceField,
  showEmailField,
  showSourceField,
}: SignupFormPropsType) {
  const [dropdownActive, setDropdownActive] = useState(false);
  const dropdownOptions = [...shuffleContents(DROPDOWN_CONTENT), OPTION_OTHER];

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleOnSubmit}>
      {showEmailField && (
        <>
          <Typography body="text-md-medium" margin="32px 0 8px">
            Email
          </Typography>
          <div className={classNames(formStyle.inputContainer, {
            [formStyle.inputContainerDisabled]: emailDisabled,
            [formStyle.inputError]: errEmail,
          })}
          >
            <input
              className={formStyle.input}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setshowSourceField(Boolean(e.target.value));
              }}
              type="email"
              placeholder="Enter your email address to continue..."
              required
              disabled={emailDisabled}
            />
          </div>
        </>

      )}

      {showSourceField && (
        <div css={[styFadeInFromBottom]}>
          <div css={[styFormInputContainer]}>
            <Typography body="text-md-medium" margin="32px 0 8px">
              How did you hear about us?
            </Typography>
            <div className={classNames(
              'dropdown',
              styles.dropdown,
              {
                'is-active': dropdownActive,
              },
            )}
            >
              <div className={classNames('dropdown-trigger', styles['dropdown-trigger'])}>
                <button
                  type="button"
                  className={classNames('button is-fullwidth py-5 my-0', styles.button, formStyle.inputContainer, {
                    [formStyle.inputError]: errSource,
                  })}
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={() => setDropdownActive((state) => !state)}
                >
                  <span className={classNames({
                    'is-subtitle': !inputSource,
                    'with-text-color': !!inputSource,
                  })}
                  >
                    {inputSource || 'Select a source'}
                  </span>
                  <span className="icon is-small">
                    <i className="fas fa-angle-down" aria-hidden="true" />
                  </span>
                </button>
              </div>
              <div className={classNames('dropdown-menu', styles['dropdown-menu'])} id="dropdown-menu" role="menu">
                <div className={classNames('dropdown-content', styles['dropdown-content'])}>
                  {dropdownOptions.map((c) => (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href="#"
                      className={classNames('dropdown-item', {
                        'is-active': inputSource === c,
                      })}
                      onClick={() => {
                        setInputSource(c);
                        setDropdownActive(false);
                      }}
                    >
                      {c}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {inputSource === OPTION_OTHER && (
          <div css={[styFormInputContainer]}>
            <Typography body="text-md-medium" margin="32px 0 8px">
              Please Specify
            </Typography>
            <div className={formStyle.inputContainer}>
              <input
                className={formStyle.input}
                value={inputSourceSpecific}
                onChange={(e) => setInputSourceSpecific(e.target.value)}
                type="text"
                placeholder="i.e. Youtube"
                required
              />
            </div>
          </div>
          )}
        </div>
      )}

      <Button htmlType="submit" testId="Login_submit_email" block loading={loading} disable={!email && !inputSource}>
        Sign up
      </Button>
    </form>
  );
}

export default SignupForm;
