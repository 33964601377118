export const shuffleContents = (contents: string[]) => {
  const array = contents;
  const lastItem = array.pop(); // Saving the last item 'Other' to the last
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    // eslint-disable-next-line no-param-reassign
    array[i] = array[j];
    // eslint-disable-next-line no-param-reassign
    array[j] = temp;
  }
  array.push(lastItem);
  return array;
};
