import classNames from 'classnames';
// import ButtonBlack from '@/sharedComponents/Button/ButtonBlack';
import Button from '@/sharedComponents/v1/Components/Button';
import type { FormEvent } from 'react';
import { Typography } from '@/sharedComponents/v1/Components/Typography';
import formStyle from '../FormStyle.module.css';
import type { LoginFormPropsType } from './type';

export default function LoginForm({
  email,
  setEmail,
  onSubmit,
  emailDisabled,
  loading,
  showEmailField,
  errEmail,
}: LoginFormPropsType) {
  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleOnSubmit}>
      { showEmailField && (
        <>
          <Typography body="text-md">
            Email
          </Typography>
          <div
            className={classNames(formStyle.inputContainer, {
              [formStyle.inputContainerDisabled]: emailDisabled,
              [formStyle.inputError]: errEmail,
            })}
          >
            <input
              data-testid="Login_email_input"
              className={formStyle.input}
              value={email || ''}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter your email address to continue..."
              required
              disabled={emailDisabled}
            />
          </div>
        </>

      )}

      <Button htmlType="submit" testId="Login_submit_email" block loading={loading} disable={!email}>
        Login
      </Button>
    </form>
  );
}
