import { useState, useEffect, useCallback } from 'react';
import { XCircle } from '@styled-icons/boxicons-solid';
import {
  GoogleToken, AccessTokenHandler, STokenHandler, PTokenHandler, ISpace, ISpacePermission,
} from '@typedream/data';
import classNames from 'classnames';
import Image from 'next/image';
import mixpanel from 'mixpanel-browser';
import type { AxiosResponse } from 'axios';
import {
  SOURCE_STORAGE_KEY,
  USER_STORAGE_KEY,
  SPACE_STORAGE_KEY,
  SPACE_PERMISSION_STORAGE_KEY,
  PROMO_SOURCE_STORAGE_EXPIRY,
  PROMO_SOURCE_STORAGE_KEY,
  ENABLE_NEW_WORKSPACE,
  ENABLE_NEW_DASHBOARD,
} from '@/constants';
import { errToString } from '@/utils/errors';
import useLoggedInRedirect from '@/utils/useLoggedInRedirect';
import getSpacePageID from '@/utils/getSpacePageID';
import { setLocalStorageWithExpiry } from '@/utils/localStorageExpire';
import { CREATE_SITE_STEPS_ORDER } from '@/utils/loginSteps';
import { api } from '@/data/api';
import GoogleSignInButton from '@/components/GoogleSignInButton';
import Loading from '@/components/Loading';
import type { IUser } from '@/types';
import styles from '@/styles/Onboarding.module.css';
import VerifyCode from './VerifyCode';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import formStyle from './FormStyle.module.css';
import type { LoginSignUpFormPropsType } from './type';

export default function LoginSignupForm({
  login,
  redirect,
  onSuccess,
  loginWithEmail = '',
}: LoginSignUpFormPropsType) {
  const [email, setEmail] = useState('');
  const [emailDisabled, setemailDisabled] = useState(false);
  const [err, seterr] = useState('');
  const [errEmail, seterrEmail] = useState(false);
  const [errSource, seterrSource] = useState(false);
  const [formSuccess, setformSuccess] = useState(false);
  const [inputSource, setInputSource] = useState('');
  const [inputSourceSpecific, setInputSourceSpecific] = useState('');
  const [loading, setloading] = useState(false);
  const [oauthJWT, setoauthJWT] = useState<GoogleToken>();
  const [googleEmail, setGoogleEmail] = useState('');
  const [isLoginWithGoogle, setIsLoginWithGoogle] = useState(false);
  const [showSourceField, setshowSourceField] = useState(false);

  const redirectLoading = useLoggedInRedirect(!formSuccess && redirect);
  const loginRedirectLoading = redirect ? redirectLoading : false;

  const handleWorkspaceRedirection = (newUrl: string, oldUrl: string) => {
    window.location.href = ENABLE_NEW_WORKSPACE ? newUrl : oldUrl;
  };

  const verifySuccessHandler = useCallback(async (r: AxiosResponse<{
    'user': IUser
    'spaces': ISpace[],
    'space_permissions': ISpacePermission[],
    'token': string,
    'new': boolean
  }>, source: string) => {
    const data = r?.data;

    AccessTokenHandler.storeTokens(data?.token);
    localStorage?.setItem(USER_STORAGE_KEY, JSON.stringify(data?.user));
    localStorage?.setItem(SPACE_STORAGE_KEY, JSON.stringify(data?.spaces));
    localStorage?.setItem(
      SPACE_PERMISSION_STORAGE_KEY,
      JSON.stringify(data?.space_permissions),
    );
    STokenHandler.removeTokens();
    PTokenHandler.removeTokens();

    const {
      defaultSpace,
      defaultSpaceID,
      defaultPageID,
      defaultSpaceSlug,
    } = getSpacePageID(
      false,
    );

    const user = data?.user as IUser;
    if (data?.new) {
      setLocalStorageWithExpiry(
        PROMO_SOURCE_STORAGE_KEY,
        source,
        PROMO_SOURCE_STORAGE_EXPIRY,
      );

      if (onSuccess) {
        // indicate new user on success
        onSuccess(true);
        return;
      }
      handleWorkspaceRedirection('/onboarding', `${CREATE_SITE_STEPS_ORDER[0].link}?onboarding=true`);
    } else {
      mixpanel.identify(user?.id);
      mixpanel.people.set({
        last_auth: new Date(),
      });
      mixpanel.register({
        number_of_sites: (r?.data?.spaces || []).length,
        user_persona: user?.metadata?.persona,
      });

      if (onSuccess) {
        onSuccess();
        setloading(false);
        return;
      }

      // Check if new user finish onboarding
      if (data?.spaces?.length <= 1) {
        handleWorkspaceRedirection('/onboarding', `${CREATE_SITE_STEPS_ORDER[0].link}?onboarding=true`);
        return;
      }
      if (!defaultSpaceID || !defaultPageID) {
        window.location.href = '/dashboard/sites';
        return;
      }

      if (ENABLE_NEW_DASHBOARD) {
        window.location.href = `/dashboard/${defaultSpaceID}`;
        return;
      }

      // TODO: Remove this after new dashboard is released
      if (defaultSpace?.metadata?.site_type === 'link_in_bio') {
        window.location.href = `/link/s/${defaultSpaceID}/${defaultPageID}`;
      } else {
        window.location.href = `/s/${defaultSpaceSlug}/${defaultSpaceID}/${defaultPageID}`;
      }
    }
  }, [onSuccess]);

  const verify = useCallback(async (token: string, withOAuth: boolean) => {
    setloading(true);
    const source = localStorage?.getItem(SOURCE_STORAGE_KEY);
    localStorage?.removeItem(SOURCE_STORAGE_KEY);

    const data = {
      email,
      token,
      source: null,
      tracking_id: mixpanel.get_distinct_id(),
      metadata: null,
    };

    if (source && source !== 'undefined' && source !== 'null') {
      data.source = source;
    }

    if (!login) {
      const hearFrom = inputSource === 'Other' ? `${inputSource}:${inputSourceSpecific}` : inputSource;
      data.metadata = {
        hear_from: hearFrom,
      };
    }

    try {
      let r: AxiosResponse<any>;
      if (withOAuth) {
        r = await api().user().verifyGoogle(data);
      } else {
        r = await api().user().verify(data);
      }
      await verifySuccessHandler(r, source);
    } catch (e) {
      seterr(errToString(e));
      setloading(false);
    }
  }, [email, inputSource, inputSourceSpecific, login, verifySuccessHandler]);

  function reload() {
    window.location.reload();
  }

  const onSubmit = useCallback(async () => {
    if (loading) return;
    if (!email && !oauthJWT?.payload?.email) {
      seterrEmail(true);
      return;
    }
    if (!login && (!inputSource || (inputSource === 'Other' && !inputSourceSpecific))) {
      seterrSource(true);
      return;
    }
    seterrEmail(false);
    seterrSource(false);
    setloading(true);
    seterr(null);
    try {
      const data = {
        email: email || oauthJWT?.payload?.email,
      };

      if (login) {
        await api().user().login(data, !!oauthJWT?.token);
      } else {
        await api().user().signup(data, !!oauthJWT?.token);
      }

      if (oauthJWT?.token) {
        verify(oauthJWT?.token, true);
      } else {
        setformSuccess(true);
      }
    } catch (e) {
      seterr(errToString(e));
      setformSuccess(false);
    } finally {
      setloading(false);
    }
  }, [email,
    inputSource,
    inputSourceSpecific,
    loading,
    login,
    oauthJWT?.payload?.email,
    oauthJWT?.token,
    verify,
  ]);

  useEffect(() => {
    seterrEmail(false);
    seterrSource(false);
  }, [email, oauthJWT, inputSource]);

  useEffect(() => {
    if (loginWithEmail) {
      setEmail(loginWithEmail);
      setemailDisabled(true);
    }
  }, [loginWithEmail]);

  useEffect(() => {
    if (isLoginWithGoogle && googleEmail) {
      if (login) {
        onSubmit();
      } else {
        setshowSourceField(true);
      }
      setIsLoginWithGoogle(false);
    }
  }, [login, googleEmail, onSubmit, isLoginWithGoogle]);

  // get OAuth Spesific value
  useEffect(() => {
    if (oauthJWT?.payload?.email) {
      setGoogleEmail(oauthJWT?.payload?.email);
      setIsLoginWithGoogle(true);
    }
  }, [oauthJWT?.payload?.email]);

  return (
    <>
      {!formSuccess ? (
        <div className={classNames('mb-2', styles.formContainer)}>
          <GoogleSignInButton login={login} setoauthJWT={setoauthJWT} />
          {oauthJWT && (
            <div className={formStyle.googleContinueContainer}>
              <div className="is-flex is-align-items-center">
                <Image src="/images/google.svg" alt="Google Logo" width={18} height={18} />
                <span className={formStyle.text}>
                  Continue as
                  {' '}
                  <span className="with-text-color">{oauthJWT?.payload?.email}</span>
                </span>
              </div>
              <div role="none" onClick={reload}>
                <XCircle
                  className="is-subtitle with-cursor-pointer"
                  size={20}
                />
              </div>
            </div>
          )}

          {!oauthJWT && (
            <div className={classNames(
              'is-size-6 with-lighter-text-color my-5 has-text-centered is-flex is-align-items-center',
              formStyle.or,
            )}
            >
              <hr />
              <span className="mx-3">OR</span>
              <hr />
            </div>
          )}

          {login ? (
            <LoginForm
              email={email}
              emailDisabled={emailDisabled}
              errEmail={errEmail}
              loading={loading}
              onSubmit={onSubmit}
              setEmail={setEmail}
              showEmailField={!oauthJWT}
            />
          ) : (
            <SignupForm
              email={email}
              emailDisabled={emailDisabled}
              errEmail={errEmail}
              errSource={errSource}
              inputSource={inputSource}
              inputSourceSpecific={inputSourceSpecific}
              loading={loading}
              onSubmit={onSubmit}
              setEmail={setEmail}
              setInputSource={setInputSource}
              setInputSourceSpecific={setInputSourceSpecific}
              setshowSourceField={setshowSourceField}
              showEmailField={!oauthJWT}
              showSourceField={showSourceField}
            />
          )}
        </div>
      ) : (
        <VerifyCode
          email={email}
          err={err}
          loading={loading}
          onSubmit={onSubmit}
          setErr={seterr}
          verify={verify}
        />
      )}

      {err && (
        <div
          className={classNames(styles.error, 'has-background-danger-light has-text-danger')}
        >
          {err}
        </div>
      )}
      <Loading loading={loading || loginRedirectLoading} />
    </>
  );
}
