import { CircleNotch } from '@styled-icons/fa-solid';
import {
  styButton,
  styButtonBlock,
  styButtonEffect,
  styButtonGhost,
  styButtonRadius,
  styButtonSize,
  styButtonVariant,
  styButtonWidth,
} from './style';
import type { ButtonPropsType } from './type';

function Button({
  block = false,
  children,
  disable = false,
  ghost = false,
  loading = false,
  prefix,
  size = 'sm',
  radius = 'sm',
  suffix,
  type = 'primary',
  width = 'fitContent',
  htmlType,
  onClick,
  testId,
}: ButtonPropsType) {
  const btnStyleCss = [
    styButton,
    styButtonVariant?.[type],
    styButtonSize?.[size],
    styButtonRadius?.[radius],
    styButtonWidth?.[width],
    ghost && styButtonGhost?.[type],
    block && styButtonBlock,
    !disable && styButtonEffect({ ghost: type !== 'tertiary' && ghost, type }),
  ];

  return (
    <button
      css={btnStyleCss}
      // eslint-disable-next-line react/button-has-type
      type={htmlType || 'button'}
      onClick={onClick}
      disabled={disable || loading}
      data-testid={testId}
    >
      {loading ? (
        <CircleNotch className="styButtonLoading" />
      ) : (
        <>
          {prefix}
          <span>
            {children}
          </span>
          {suffix}
        </>
      )}
    </button>
  );
}

export default Button;
