import { ISpace } from '@typedream/data';
import { DEFAULT_SPACE_STORAGE_KEY, SPACE_STORAGE_KEY } from '../constants';

export default function getSpacePageID(
  defaultFirstSpace: boolean,
): {
  defaultSpaceSlug?: string;
  defaultSpaceID?: string;
  defaultPageID?: string;
  defaultSpace?: ISpace
  noSpaces: boolean;
  spacesCount?: number
} {
  const spacesStr = localStorage?.getItem(SPACE_STORAGE_KEY);
  let spaces = null;
  if (spacesStr) {
    spaces = JSON.parse(spacesStr);
  }
  if (!spacesStr || !spaces || spaces?.length <= 0) {
    return { noSpaces: true };
  }
  let defaultSpaceID = localStorage?.getItem(DEFAULT_SPACE_STORAGE_KEY);
  let defaultPageID = null;
  let defaultSpaceSlug = null;
  let defaultSpace = null;

  if (
    (!defaultSpaceID || defaultSpaceID === 'null' || defaultSpaceID === 'undefined')
    || (!spaces?.map((s) => s?.id).includes(defaultSpaceID) && defaultFirstSpace)
  ) {
    defaultSpaceID = spaces && spaces.length > 0 ? spaces[0].id : null;
    localStorage?.setItem(DEFAULT_SPACE_STORAGE_KEY, defaultSpaceID);
  }
  if (spaces?.length > 0) {
    const defaultSpaceArr = spaces.filter((s) => s?.id === defaultSpaceID);
    defaultSpace = defaultSpaceArr.length > 0 ? defaultSpaceArr[0] : null;
    defaultPageID = defaultSpace ? defaultSpace.routes?.page_id : null;
    defaultSpaceSlug = defaultSpace?.slug;
  }
  return {
    defaultSpace,
    defaultSpaceID,
    defaultPageID,
    defaultSpaceSlug,
    noSpaces: false,
    spacesCount: spaces?.length,
  };
}
